import React from 'react'
import OurCompanyImage from '../../Image/About/ourCompanyImage'
import './index.css'
import { LanguageContext } from '../../../lang'
import { useContext } from 'react'

const OurCompanySection = () => {
  const {
    translations: { about }
  } = useContext(LanguageContext)
  const { headingText, paraText } = about.ourCompany
  return (
    <section>
      <div className='container'>
        <div className='row pt-5 pb-5'>
          <div className='col-lg-6'>
            {/* <OurCompanyImage /> */}
            <img
            className="img-fluid"
            src={require("../../../images/about/about-img-1.png")}
          />
          </div>
          <div className='col-lg-6 mt-lg-0 mt-md-4'>
            <h3 className='about-h3'>{headingText}</h3>
            <p className='about-p'>{paraText}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurCompanySection
