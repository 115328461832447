import React from 'react';
import AboutImage1 from '../../Image/About/aboutImage-1';
import AboutImage2 from '../../Image/About/aboutImage-2';
import AboutImage3 from '../../Image/About/aboutImage-3';
import AboutImage4 from '../../Image/About/aboutImage-4';
import AboutImage5 from '../../Image/About/aboutImage-5';
import AboutImage6 from '../../Image/About/aboutImage-6';
import AboutImage7 from '../../Image/About/aboutImage-7';
import './index.css';

const ImageSection = () => (
  <section id="image-section">
    <div className="container">
      <div className="row pb-5">
        <div className="col-12">
          <AboutImage1 />
        </div>
      </div>
      <div className="row pb-5">
        <div className="col-md-6">
          <AboutImage2 />
        </div>
        <div className="col-md-6">
          <AboutImage3 />
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-md-8 img-4">
          <AboutImage4 />
        </div>
        <div className="col-md-4">
          <AboutImage5 />
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-md-4">
          <AboutImage6 />
        </div>
        <div className="col-md-8 img-7">
          <AboutImage7 />
        </div>
      </div>
    </div>
  </section>
);


export default ImageSection;
