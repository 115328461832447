import React, { Component } from "react";
import HeroSection from "./Hero-Section";
import OurCompanySection from "./Our-Company";
import ImageSection from "./Images-Section";
import KnowMoreSection from "./Know-More";
import TestimonialSection from "../Common/Testimonial-Section";
import "./index.css";
import AboutTestimonial from "./Testimonial";

class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <HeroSection />
        <OurCompanySection />
        <AboutTestimonial />
        <ImageSection />
        <KnowMoreSection />
        {/* <TestimonialSection /> */}
      </React.Fragment>
    );
  }
}

export default AboutUs;
