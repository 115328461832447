import React from 'react'
// import TestimonialImage from '../../Image/About/testimonialImage'
import './index.css'
import { useContext } from 'react'
import { LanguageContext } from '../../../lang'

const AboutTestimonial = () => {
  const {
    translations: { about }
  } = useContext(LanguageContext)
  const {
    headingText,
    paraText1,
    bullet1,
    bullet2,
    bullet3,
    paraText2,
    paraText3
  } = about.whyTaxiOnSpot
  return (
    <section>
      <div className='container'>
        <div className='row pt-5 pb-5 '>
          <div className='col-lg-6'>
            <h3 className='about-h3'>{headingText}</h3>
            <br />

            <p className='about-p'>
              {paraText1}
              <br />
              <br />
              <ul>
                <li>{bullet1}</li>
                <li>{bullet2}</li>

                <li>{bullet3}</li>
              </ul>
              {paraText2}
              <br />
              <br />

              {paraText3}
            </p>
          </div>

          <div className='col-lg-6 mt-lg-0 mt-md-4 ms-order'>
            {/* <TestimonialImage /> */}
            <img
            className="img-fluid"
            src={require("../../../images/about/about-img-2.png")}
          />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutTestimonial
