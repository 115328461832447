import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const AboutImage1 = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about/about-img-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

return (
  <>
          <img
          className="img-fluid"
          src={require("../../../images/about/about-img-3.png")}
        />
  </>
)
}

export default AboutImage1
