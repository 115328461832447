import React from "react";
import "./index.css";
import { useContext } from "react";
import { LanguageContext } from "../../../lang";
const HeroSection = () => {
  const {
    translations: { home },
  } = useContext(LanguageContext);
  const { ourCompanyText, aboutText } = home.heroSection.about;
  return (
    <section id="about-hero-section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="about-h1">
              {ourCompanyText} <span>{aboutText}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
